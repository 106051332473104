const PrivacyPolicy = () => {
  return (
    <div>
      <iframe
        className="pdf-viewer"
        src="/assets/privacy-policy.pdf"
        title="Privacy Policy"
      />
      <div className="version-content">
        <h5>Beta release</h5>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
