import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { loginUserData } from "../../../store/action";

import "./style.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const checkAuthSession = async () => {
      setLoader(true);
      try {
        const data = await Auth.currentSession();
        setLoader(false);
        dispatch(
          loginUserData({
            token: data?.idToken?.jwtToken,
            refreshToken: data?.refreshToken?.token,
            username: data?.accessToken?.payload?.username,
            email: data?.idToken?.payload?.email
          })
        );
        navigate("/analysis");
      } catch (err) {
        setLoader(false);
        console.log(err);
      }
    };
    checkAuthSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await Auth.signIn(email, password);
      setLoader(false);
      dispatch(
        loginUserData({
          token: response?.signInUserSession?.idToken?.jwtToken,
          refreshToken: response?.signInUserSession?.refreshToken?.token,
          username: response.username,
          email
        })
      );
      swal({ icon: "success", text: "Login successful" });
      navigate("/analysis");
    } catch (error) {
      setLoader(false);
      swal(error?.message);
    }
  };

  return (
    <div className="content">
      <div className="row-container">
        <div className="col col-1">
          <img src="/assets/rent-1.png" alt="rent-cover-left" />
        </div>
        <div className="col col-2">
          <div className="inner-content">
            <h3 className="welcom-text">Login</h3>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <Input
                  label="Email"
                  type="email"
                  placeholder={"Enter email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Password"
                  type="password"
                  placeholder={"Enter password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="bottom-content p-0">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
              <div className="text-center">
                <Button
                  title={
                    loader ? <i className="spin fas fa-spinner"></i> : "Sign in"
                  }
                  type="submit"
                  color={"#2791c8"}
                  className="login-btn"
                  disabled={loader}
                />
              </div>
            </form>
            <div className="bottom-content">
              <p>Don't have an account?</p>{" "}
              <Link to="/signup">Create Account</Link>
            </div>
            <div className="text-center">
              <div className="or-text">OR</div>
            </div>
            <div className="pt-3 bottom-content">
              <p>Verify your account?</p>{" "}
              <Link to="/verify-account">Verify</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="version-content">
        <h5>Beta release</h5>
      </div>
    </div>
  );
};

export default Login;
