import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const Dropdown = ({ label = "Default", children, className = "" }) => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef(null);

  const closeOpenMenus = (e) => {
    if (ref.current && toggle && !ref.current.contains(e.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [toggle]);

  return (
    <div
      ref={ref}
      className={`${className} dropdown-container`}
      onClick={() => setToggle(!toggle)}
    >
      <div className="dropdown-label">
        {label}
        <i className="fas fa-caret-down ms-2"></i>
      </div>
      <div className={`dropdown-content ${toggle ? "d-block" : "d-none"} `}>
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
