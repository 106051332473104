import React from "react";

import "./style.css";

const Button = ({
  type = "button",
  title,
  className = "",
  color = "#696969",
  textColor = "#fff",
  onClick,
  disabled=false
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${className} btn-custom`}
      style={{
        backgroundColor: color,
        color: textColor,
        borderColor: color,
      }}
      onClick={onClick ? onClick : null}
    >
      {title}
    </button>
  );
};

export default Button;
