import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import Button from "../../../components/Button";
import Input from "../../../components/Input";

import "./style.css";

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await Auth.signUp({
        username: email,
        password: password,
        attributes: { email }
      });
      console.log({ response });
      swal(
        "Registration successful. Code has been sent to your email address."
      ).then(() => {
        navigate("/verify-account", { state: { email } });
      });
      setLoader(false);
    } catch (error) {
      console.log({ error });
      setLoader(false);
      swal(error?.message);
    }
  };

  return (
    <div className="content">
      <div className="row-container">
        <div className="col col-1">
          <img src="/assets/rent-1.png" alt="rent-cover-left" />
        </div>
        <div className="col col-2">
          <div className="inner-content">
            <h3 className="welcom-text">Register Now</h3>
            <form onSubmit={handleSignup}>
              <div className="mb-4">
                <Input
                  label="Email"
                  type="email"
                  placeholder={"Enter email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Password"
                  type="password"
                  placeholder={"Enter password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="text-center">
                <Button
                  title={
                    !loader ? (
                      "Sign up"
                    ) : (
                      <i className="spin fas fa-spinner"></i>
                    )
                  }
                  color="#2791c8"
                  type="submit"
                  disabled={loader}
                  className="register-btn"
                />
              </div>
            </form>
            <div className="bottom-content">
              <p>Have an account?</p> <Link to="/login">Login</Link>
            </div>
            <div className="bottom-content">
              <p>
                By signing up, I agree to the{" "}
                <Link to="/terms-of-services">Terms of Service</Link> and{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="version-content">
        <h5>Beta release</h5>
      </div>
    </div>
  );
};

export default Register;
