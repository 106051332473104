import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import swal from "sweetalert";

import Dropdown from "../Dropdown";
import { logoutUser } from "../../store/action";

import "./style.css";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.userData);

  const logOut = async () => {
    try {
      await Auth.signOut();
      dispatch(logoutUser());
      swal({ icon: "success", text: "Logout successful" });
    } catch (error) {
      console.log("Error logging out:", error);
    }
  };

  return (
    <div className="navbar">
      <div className="navbar-content">
        <Dropdown className="me-2" label={<img className="avatar" src="/assets/avatar.png"/>}>
          <div>{user?.email}</div>
        </Dropdown>
        <li onClick={logOut}>Logout</li>
      </div>
    </div>
  );
};

export default Header;
