import "./style.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import { loginUserData } from "../../../store/action";

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const checkAuthSession = async () => {
      setLoader(true);
      try {
        const data = await Auth.currentSession();
        setLoader(false);
        dispatch(
          loginUserData({
            token: data?.idToken?.jwtToken,
            refreshToken: data?.refreshToken?.token,
            username: data?.accessToken?.payload?.username,
            email: data?.idToken?.payload?.email
          })
        );
        navigate("/analysis");
      } catch (err) {
        setLoader(false);
        console.log(err);
      }
    };
    checkAuthSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="landing-wrapper">
        <div className="landing-top">
          <div className="landing-top-img">
            <img src="/assets/images/landing-top.png" alt="no-image" />
            <div className="landing-top-btn">
              <button
                type="button"
                className="btn-black"
                onClick={() => navigate("/signup")}
              >
                Start now
              </button>
            </div>
          </div>

          <div className="top-right-content">
            <div>
              <img
                src="/assets/images/top-text.png"
                alt="no-image"
                className="top-image"
              />
            </div>
            <button
              type="button"
              className="btn-black"
              onClick={() => navigate("/login")}
            >
              Log in
            </button>
          </div>
        </div>
      </div>

      <div className="landing-section-work">
        <h4>How it works</h4>
        <div className="row mt-4">
          <div className="col-md-6 work-margin">
            <div className="work-card">
              <div className="work-card-img">
                <img src="/assets/images/work-1.png" alt="no-image" />
              </div>
              <div className="work-card-footer">
                <div className="work-number">1.</div>
                <div className="work-footer-padding">
                  <p>Simply enter a residential property address</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 work-margin">
            <div className="work-card">
              <div className="work-card-img">
                <img src="/assets/images/work-2.png" alt="no-image" />
              </div>
              <div className="work-card-footer">
                <div className="work-number">2.</div>
                <div className="work-footer-padding">
                  <p>
                    The tool will search the web and collect all relevant data
                    for that property
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 work-margin">
            <div className="work-card">
              <div className="work-card-img">
                <img src="/assets/images/work-3.png" alt="no-image" />
              </div>
              <div className="work-card-footer">
                <div className="work-number">3.</div>
                <div className="work-footer-padding">
                  <p>
                    It will then generate industry standard cash flow and
                    profitability metrics
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 work-margin">
            <div className="work-card">
              <div className="work-card-img">
                <img src="/assets/images/work-4.png" alt="no-image" />
              </div>
              <div className="work-card-footer">
                <div className="work-number">4.</div>
                <div className="work-footer-padding">
                  <p>
                    Refine input values based on your preferences and expertise,
                    and rerun unlimited optimized analyses
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing-section-benefit">
        <div className="benfit-width">
          <h1>Benefits</h1>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="benefit-card">
                <div className="benefit-card-img">
                  <img src="/assets/images/benefit-1.jpg" alt="no-image" />
                </div>
                <h4>Metrics</h4>
                <p>Instantly generate cash flow and profitability metrics</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="benefit-card">
                <div className="benefit-card-img">
                  <img src="/assets/images/benefit-2.jpg" alt="no-image" />
                </div>
                <h4>ANALYSIS</h4>
                <p>Optimize and refine your analysis</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="benefit-card">
                <div className="benefit-card-img">
                  <img src="/assets/images/benefit-3.jpg" alt="no-image" />
                </div>
                <h4>DATA SOURCING</h4>
                <p>
                  Auto-populated market-based property values, taxes, rents,
                  etc.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4">
              <div className="benefit-card">
                <div className="benefit-card-img">
                  <img src="/assets/images/benefit-4.jpg" alt="no-image" />
                </div>
                <h4>AUTOMATED ENTRY</h4>
                <p>Save endless hours of data entry into spreadsheets</p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="benfit--sing-btn"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>

      <div className="landing-analyze">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="landing-analyze-content">
              <h1>Analyze Properties</h1>
              <p>
                We're building the next generation of real estate analysis tools
                to help investors, realtors, landlords, and property managers!
              </p>
              <button type="button" onClick={() => navigate("/login")}>
                Get started for free
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="landing-analyze-img">
              <img src="/assets/images/analyze.jpg" alt="no-image" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <h5>Terms & Support</h5>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
