import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import swal from "sweetalert";

import Button from "../../../components/Button";
import Input from "../../../components/Input";

import "./style.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [requestProcessed, setRequestProcessed] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const handelForgotPassword = async () => {
    setLoader(true);
    try {
      await Auth.forgotPassword(email);
      swal("please check your email to continue.").then(() => {
        setRequestProcessed(true);
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      swal(error?.message);
    }
  };

  const setNewPassword = async () => {
    setLoader(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      swal("password updated, please login to continue.").then(() => {
        navigate("/");
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (error?.code == "InvalidLambdaResponseException") {
        swal("password updated, please login to continue.").then(() => {
          navigate("/");
        });
      } else {
        swal(error?.message);
      }
    }
  };

  const resendVerificationCode = async () => {
    setResendLoading(true);
    try {
      await Auth.forgotPassword(email);
      swal("Verification code resent");
      setResendLoading(false);
    } catch (error) {
      setResendLoading(false);
      swal(error?.message);
    }
  };


  return (
    <div className="content">
      <div className="row-container">
        <div className="col col-1">
          <img src="/assets/rent-1.png" alt="rent-cover-left" />
        </div>
        <div className="col col-2">
          <div className="inner-content">
            <h3 className="welcom-text">Forgot Password</h3>
            <div className="mb-4">
              <Input
                label="Email"
                type="email"
                placeholder={"Enter email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {requestProcessed && (
              <>
                <div className="mb-4">
                  <Input
                    label="Verification code"
                    type="text"
                    placeholder={"Enter Code"}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    label="Password"
                    type="password"
                    placeholder={"Enter password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </>
            )}
            <div className="text-center">
              <Button
                title={
                  loader ? (
                    <i className="spin fas fa-spinner"></i>
                  ) : requestProcessed ? (
                    "Set New Password"
                  ) : (
                    "Submit"
                  )
                }
                disabled={loader}
                color="#2791c8"
                className="verify-btn"
                onClick={
                  requestProcessed ? setNewPassword : handelForgotPassword
                }
              />
            </div>
            {/* <div
              className="text-center mt-5 cursor-pointer text-grey"
              onClick={!resendLoading && resendVerificationCode}
            >
              {resendLoading ? (
                <i className="spin fas fa-spinner me-2"></i>
              ) : null}
              Resend Code
            </div> */}
            <div className="bottom-content">
              <p>Have an account?</p> <Link to="/">Login</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="version-content">
        <h5>Beta release</h5>
      </div>
    </div>
  );
};

export default ForgotPassword;
