function loginUserData(userData) {
  return {
    type: "LOGIN_WITH_EMAIL",
    userData: userData,
  };
}

function logoutUser() {
  return {
    type: "LOGOUT_USER",
  };
}

function search(payload) {
  return {
    type: "SEARCH",
    data: payload?.data,
    analysisOutput: payload?.analysisOutput
  };
}

export { loginUserData, logoutUser, search };
