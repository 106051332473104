import React, { useState } from "react";

import "./style.css";

const Input = ({
  label = "",
  type = "text",
  placeholder,
  value = "",
  onChange,
  className = "",
  disabled = false,
}) => {
  const [changeType, setChangeType] = useState(false);

  return (
    <div className="input-container">
      <label className="input-label">{label}</label>
      <input
        type={changeType ? "text" : type}
        className={`${className} input`}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange && onChange}
      />
      {type === "password" && (
        <div
          className="input-right-icon"
          onClick={() => setChangeType(!changeType)}
        >
          <i className={`fas ${!changeType ? "fa-eye-slash" : "fa-eye"}`}></i>
        </div>
      )}
    </div>
  );
};

export default Input;
