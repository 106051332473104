import { Navigate, Route, Routes } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Verify from "../pages/Auth/Verfiy";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Analysis from "../pages/Main/Analysis";
import AnalysisDetail from "../pages/Main/Analysis/details";
import LandingPage from "../pages/Main/LandingPage";
import ProtectedRoute from "./ProtectedRoute";

// common routes
import TermsOfService from "../pages/Common/TermOfService";
import PrivacyPolicy from "../pages/Common/PrivacyPolicy";
import CookiePolicy from "../pages/Common/CookiePolicy";
import Disclaimer from "../pages/Common/Disclaimer";
import { loginUserData } from "../store/action";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
});

function Router() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [documentVisible, setDocumentVisibility] = useState(false);

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      setDocumentVisibility(true);
    } else {
      setDocumentVisibility(false);
    }
  });

  useEffect(() => {
    const checkAuthSession = async () => {
      try {
        const data = await Auth.currentSession();
        dispatch(
          loginUserData({
            token: data?.idToken?.jwtToken,
            refreshToken: data?.refreshToken?.token,
            username: data?.accessToken?.payload?.username,
            email: data?.idToken?.payload?.email
          })
        );
        navigate(location.pathname !== "/" ? location.pathname : "/analysis");
      } catch (err) {
        navigate(location.pathname !== "/" ? location.pathname : "/analysis");
      }
    };
    if (documentVisible && location.pathname.includes("/analysis")) {
      checkAuthSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentVisible]);
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />
      <Route path="/verify-account" element={<Verify />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/analysis" element={<ProtectedRoute />}>
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/analysis/details" element={<AnalysisDetail />} />
      </Route>
      <Route path="/terms-of-services" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default Router;
